import Body from "./Components/Body/Body.js";
import Header from "./Components/Header/Header.js";
import "./App.css";
import Project from "./Components/Projects/Project.js";
import { Box } from "@mui/material";
import Skills from "./Components/Skills/Skills.js";
import Contact from "./Components/Contact/Contact.js";
import Footer from "./Components/Footer/Footer.js";
import { createContext, useEffect } from "react";
import { useState } from "react";

export const ModeContext = createContext();

function App() {
    const [darkMode, setDarkMode] = useState(true);

    const handleMode = () => {
        setDarkMode(!darkMode);
    };
    useEffect(() => {
        document.body.classList.toggle("dark-mode", darkMode);
        document.body.classList.toggle("light-mode", !darkMode);
    }, [darkMode]);

    return (
        <>
            <ModeContext.Provider value={darkMode}>
                <Header darkMode={darkMode} handleMode={handleMode} />
                <Box paddingTop={8}>
                    <Body />
                    <Project />
                    <Skills />
                    <Contact />
                </Box>
                <Footer />
            </ModeContext.Provider>
        </>
    );
}

export default App;
