import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Buttons from "../BtnGroup/Btn";
import { useContext } from "react";
import { ModeContext } from "../../App";
import Coding from "../../icons/Coding";

const FadeInTypography = styled(Typography)`
    opacity: 0;
    animation: fadeInAnimation 2s ease-in forwards;
    @keyframes fadeInAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const About = () => {
    const darkmode = useContext(ModeContext);

    const gridItemStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
    };

    const BioHeading = ["Hi,I'm Nikhil", "I love to build amazing apps"];

    return (
        <>
            <Grid
                container
                spacing={2}
                paddingLeft={2}
                sx={{ paddingTop: { xs: "1rem", md: "3rem" } }}
            >
                <Grid item xs={6} sm={8} md={6} style={gridItemStyle}>
                    <Box>
                        {BioHeading.map((bioheading, index) => (
                            <FadeInTypography
                                key={index}
                                variant="h5"
                                sx={{
                                    mr: 2,
                                    fontWeight: 500,
                                    // letterSpacing: ".1rem",
                                    fontFamily: "monospace",
                                    color: darkmode ? "white" : "black",
                                    // fontSize: "2rem",
                                    fontSize: { xs: "1.1rem", md: "2rem" },
                                    animation: "ease-in",
                                }}
                            >
                                {bioheading}
                            </FadeInTypography>
                        ))}
                    </Box>
                    <Typography
                        variant="body5"
                        sx={{
                            color: "grey",
                            fontFamily: "sans-serif",
                            fontStyle: "initial",
                            fontSize: { xs: "14px", md: "16px" },
                        }}
                    >
                        {" "}
                        Full-stack enthusiast creating complete digital
                        experiences by designing user-friendly interfaces and
                        building complex back-end systems.I bring digital dreams
                        to life.
                    </Typography>
                    <Buttons />
                </Grid>
                <Grid item xs={6} sm={4} md={6}>
                    <Coding />
                </Grid>
            </Grid>
        </>
    );
};

export default About;
