import * as React from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import "./textarea.css";
import { ModeContext } from "../../App";

const TextArea = ({ value, onChange }) => {
    const darkmode = React.useContext(ModeContext);
    const handleChange = (event) => {
        const newValue = event.target.value;
        onChange(newValue);
    };

    return (
        <TextareaAutosize
            className={darkmode?"textarea-autosize":"textarea-autosize-light"}
            aria-label="textarea"
            placeholder="Write a message..."
            value={value}
            onChange={handleChange}
        />
    );
};

export default TextArea;
