import React, { useState } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

const Buttons = () => {
    const [selectedItem, setSelectedItem] = useState("");
    const BtnHeading = ["Collaborate", "Past work"];

    const handleScrollToSkills = (index) => {
        if (index === 0) {
            const contactElement = document.getElementById("contact");
            if (contactElement) {
                contactElement.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            const skillsElement = document.getElementById("projects");
            if (skillsElement) {
                skillsElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    };
    return (
        <ButtonGroup
            // disableElevation
            aria-label="Disabled elevation buttons"
        >
            {BtnHeading.map((btnheading, index) => (
                <div key={index}>
                    <Button
                        sx={{
                            fontSize: { xs: "12px", sm: "10px", md: "16px" },
                            padding: { xs: "4px 4px", md: "6px 5px" },
                            margin: { xs: "2px", md: "8px" },
                            textTransform: "none",
                        }}
                        variant={
                            selectedItem === index ? "contained" : "outlined"
                        }
                        onClick={() => {
                            handleScrollToSkills(index);
                            setSelectedItem(index);
                        }}
                    >
                        {btnheading}
                    </Button>
                </div>
            ))}
        </ButtonGroup>
    );
};

export default Buttons;
