import React from "react";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = ({
    handleClose,
    successMessage,
    errorMessage,
    messageNotify,
}) => {
    const close = () => {
        handleClose();
    };
    return (
        <Snackbar
            open={successMessage || errorMessage}
            autoHideDuration={3000}
            onClose={close}
            message="Message"
            style={{
                left: "auto",
                right: "10px",
                bottom: "10px",
                top: "auto",
            }}
        >
            <Alert
                onClose={handleClose}
                severity={
                    errorMessage ? "error" : successMessage ? "success" : ""
                }
                sx={{
                    width: "100%",
                }}
            >
                {messageNotify}
            </Alert>
        </Snackbar>
    );
};

export default Notification;
